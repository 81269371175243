<template>
    <b-modal no-close-on-backdrop no-close-on-esc size="lg" id="form-mutasi-request">
        <b-form-group class="mb-2">
            <label>Pilih Tujuan Mutasi</label>
            <v-select :options="gudangOptions" label="text" v-model="form.tujuan" :reduce="option => option.value"></v-select>
        </b-form-group>
        <b-form-group class="mb-2">
            <label>Pilih Aset</label>
            <v-select :options="assetOptions" label="text" v-model="form.asset_id" :reduce="option => option.value"></v-select>
        </b-form-group>
        <b-form-group v-if="penyimpanans.length > 0" class="w-full" style="overflow: auto;">
            <label for="">Pilih Stok Aset</label>
            <b-table :fields="fields" :items="penyimpanans" class="w-full">
                <template #cell(radio)="{item}">
                    <b-form-radio v-if="item.stok > 0" name="stok" v-model="form.penyimpanan_dari" :value="item"></b-form-radio>
                    <feather-icon icon="SlashIcon" class="text-danger" v-else />
                </template>
                <template #cell(blok)="{item}">
                    {{item.blok ? item.blok.blok : '-'}}
                </template>
                <template #cell(jumlah)="{item}">
                    <b-form-input v-if="item.selected" v-model="form.jumlah"></b-form-input>
                </template>
                <template #cell(satuan)="{item}">
                    {{item.asset && item.asset.satuan ? item.asset.satuan.satuan : ''}}
                </template>

            </b-table>
        </b-form-group>
        <i class="text-danger" v-else>Stok Aset belum ada. Harap pilih aset lain</i>

        <template #modal-footer>
            <b-button variant="primary" :disabled="!isValidForm" @click.prevent="submit">Simpan</b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BFormRadio, BFormInput, BModal, BFormGroup, BTable, BButton} from 'bootstrap-vue'
    import vSelect from 'vue-select'
    export default {
        components: {
            BFormRadio, BFormInput, BModal, BFormGroup, BTable, BButton, vSelect
        },
        data: () => ({
            form: {
                asset_id: null,
                dari: null,
                tujuan: null,
                jumlah: 0,
                terima: 0,
                penyimpanan_dari: null
            },
            fields: [
                {
                    key: 'radio',
                    label: '#'
                },
                {
                    key: 'blok',
                    label: 'Lokasi Aset'
                },
                {
                    key: 'stok',
                    label: 'Stok'
                },
                {
                    key: 'jumlah',
                    label: 'Jumlah'
                },
                {
                    key: 'satuan',
                    label: 'Satuan'
                }
            ]
        }),
        watch: {
            "form.asset_id"(asset_id) {
                this.getPenyimpanan(asset_id)
            },
            "form.penyimpanan_dari"(penyimpanan_dari) {
                if(penyimpanan_dari && penyimpanan_dari.id) {
                    const findIndex = this.penyimpanans.findIndex(item => item.id == penyimpanan_dari.id)
                    if(findIndex >= 0) {
                        this.penyimpanans.map(item => {
                            item.selected = false
                        })
                        this.penyimpanans[findIndex].selected = true
                    }
                    this.form.jumlah = penyimpanan_dari.jumlah
                }
            },
            "$store.state.asset.current_mutasi"(val) {
                if(val) {
                    const {id, asset_id, dari, tujuan, penyimpanan_dari, jumlah, terima} = JSON.parse( JSON.stringify(val) )
                    this.form.asset_id = asset_id
                    this.form.dari = dari
                    this.form.tujuan = tujuan
                    this.form.terima = terima
                    this.form.penyimpanan_dari = penyimpanan_dari
                    this.form.jumlah = jumlah
                    this.form.id = id
                }
                else {
                    this.form.asset_id = null
                    this.form.dari = null
                    this.form.tujuan = null
                    this.form.penyimpanan_dari = null
                    this.form.jumlah = 0
                    this.form.terima = 0
                    this.form.id = null
                }
            }
        },
        methods: {
            submit() {
                if(!this.isValidForm) {
                    this.displayError({
                        message: 'Harap lengkapi form request mutasi aset'
                    })
                    return false
                }

                if(this.form.penyimpanan_dari.id) {
                    this.form.penyimpanan_dari = this.form.penyimpanan_dari.id
                }
                if(this.myGudang) this.form.dari = this.myGudang.id
                const payload = Object.assign({}, this.form)

                this.$emit('submit', this.form)
            },
            async getPenyimpanan(asset_id) {
                const params = {asset_id}
                if(this.myGudang) params.gudang_id = this.myGudang.id
                this.$emit('loading', true)
                await this.$store.dispatch('asset/getStock', params)
                this.$emit('loading', false)
            }
        },
        computed: {
            currentForm() {
                const currentForm = this.$store.state.asset.current_mutasi
                if(!currentForm) {
                    return null
                }

                return JSON.parse( JSON.stringify(currentForm) )
            },
            isValidForm() {
                if(!this.form.asset_id || !this.form.tujuan || this.form.jumlah < 1 || !this.form.penyimpanan_dari) {
                    return false
                }

                return true
            },
            penyimpanans() {
                const penyimpanans = JSON.parse( JSON.stringify(this.$store.state.asset.stocks) )
                penyimpanans.map(item => {
                    item.jumlah = item.stok
                    item.selected = false
                })
                return penyimpanans
            },
            assetOptions() {
                const assets = this.$store.state.asset.barangs
                return assets.map(item => ({ value: item.id, text: item.nama }))   
            },
            gudangOptions() {
                const gudangs = this.$store.state.gudang.datas
                const result = gudangs.filter(item => item.id != this.myGudang.id)
                return result.map(item => ({value: item.id, text: item.nama_gudang}))
            }
        }
    }
</script>